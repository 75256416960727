import {
  ProposalSummaryFragmentDoc,
  useRequestProposalRegenerationMutation,
} from '@generated';
import { useNotificationContext } from 'src/providers/NotificationProvider/useNotificationContext';
import { UUID } from 'src/types/basic';
import { NotificationType } from 'src/types/notification';

import { reviewProposalRouteWording } from '../features/reviewProposal/types/wording';

export const useRegenerateExpiredProposal = (uuid: UUID) => {
  const { showNotification } = useNotificationContext();

  return useRequestProposalRegenerationMutation({
    variables: {
      uuid,
    },
    update(cache) {
      cache.updateFragment(
        {
          fragment: ProposalSummaryFragmentDoc,
          id: `Proposal:{"uuid":"${uuid}"}`,
          fragmentName: 'ProposalSummary',
        },
        (data) => ({
          ...data,
          regeneration_requested: true,
        })
      );
    },
    onCompleted: () => {
      showNotification(
        NotificationType.Success,
        reviewProposalRouteWording.weWillSendYouEmailRepublished
      );
    },
  });
};
