import {
  PortfolioManagedBySelector,
  useGetTotalNetWorthQuery,
} from '@generated';
import { useAuthenticatedUser } from 'src/services/authentication/hooks';

export const useGetManagedAssets = () => {
  const { user } = useAuthenticatedUser();
  const userId = user?.customer?.uuid;

  const { data, loading } = useGetTotalNetWorthQuery({
    variables: {
      customer: user ? userId : undefined,
      managedBy: PortfolioManagedBySelector.Secfi,
    },
    skip: !userId,
  });

  return {
    managedAssets: data?.totalNetWorth.allocationTable.items,
    loading,
  };
};
