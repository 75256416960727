export const navItemLabels = {
  summary: 'Summary',
  details: 'Your equity & tax details',
  financingDetails: 'Financing details',
  whatToExpect: 'What to expect upon exit',
};

export const reviewProposalRouteWording = {
  back: 'Back',
  weAreRepublishing: `We're working on your updated proposal.`,
  proposalExpiredPrefix:
    'This proposal has expired. Request an updated proposal',
  reRequest: 'here',
  weWillSendYouEmailRepublished: `We'll send you an email soon with your updated proposal`,
};

export const sideNavWording = {
  selectThisProposal: 'Select this proposal',
  acceptedProposalTitle: `Your accepted proposal`,
  acceptedButton: `Proposal accepted`,
  requestSent: 'Request sent',
  reRequestProposal: 'Re-request proposal',
  acceptedHelpText: `You accepted this proposal on {{ date }}.`,
  helpText: `Selecting this proposal allows us to secure your financing, onboard you as a customer and work through the final contracts together. This does not bind you to any agreement at this stage of the process.`,
  expiresOn: 'Expires on',
  expiredOn: 'Expired on',
  closeProposal: 'Close proposal',
};

export const summaryWording = {
  mainTitle: `Your proposal`,
  mainSummaryTitle: `Summary`,
  eyebrow: `From your equity strategist`,
  content: `The proposal terms you accepted are below. Feel free to reach out to your financing team if you have any questions.`,
  blurb: `”We're thrilled to finance your equity, {{ firstName }}. If you have any questions, don't hesitate to reach out.”`,
  videoBlurb: `”We're thrilled to finance your equity, {{ firstName }}. I made a video explaining how your proposal works. If you have any questions, don't hesitate to reach out.”`,
  proposalTitle: 'Your proposal',
  breakdown: 'Breakdown of your financing proposal',
  numberOfShares: 'Number of shares',
  numberOfStockOptions: 'Number of stock options',
  numberOfStockOptionsAndShares: 'Number of stock options & shares',
  numberOfStockDescription: 'Number of shares affiliated with financing.',
  totalCashAdvance: 'Total cash advance',
  totalCashAdvanceDescription: 'Total cash advance provided by Secfi.',
};

export const equityDetailsWording = {
  mainTitle: 'Your equity & tax details',
  subTitle:
    'The details below were used to create your financing proposal. Carefully review that all information is up-to-date and correct.',
  labels: {
    four09aLabel: 'FMV',
    four09aTooltip: `The FMV valuation is the current fair market valuation of one share of your company's stock.`,
    countryLabel: 'Country',
    countryTooltip:
      'Your country of residence at the time you were employed with this company.',
    stateLabel: 'State',
    companyLabel: 'Company',
    incomeLabel: 'W-2 income',
    filingStatusLabel: 'Filing status',
    dependentsLabel: 'Dependents',
  },
  tableHeading: 'Grants included in this proposal',
  tableColumns: {
    name: 'Name',
    quantity: 'Quantity',
    date: 'Date',
    type: 'Type',
    price: 'Strike price',
  },
  somethingNotRight: `Is something not right about your equity details? [Email us](mailto:{{ advisorEmail }}) to update your proposal.`,
};

export const cashAdvanceWording = {
  mainTitle: 'Financing details',
  cashAdvanceDescription: `Here's the breakdown of your cash advance - the amount of money you are financing with Secfi - and the costs it covers.`,
  totalCashAdvance: 'Total cash advance provided by Secfi',
  totalFundsProvided: 'Total funds provided by Secfi',
  exerciseCost: 'Exercise Cost',
  exerciseCostDescription:
    'The number of stock options you are exercising multiplied by your strike price.',
  liquidityRequested: 'Liquidity amount requested',
  liquidityRequestedDescription: `The number of shares of stock you own, multiplied by the stock's fair market value.`,
  taxesDueToExerciseTitle: 'Tax costs triggered by exercising',
  taxesDueToExerciseDescription:
    "Taxes you owe when you exercise your stock options. The amount you're taxed on is typically the FMV less your strike price, multiplied by number of stock options exercised.",
  taxesDueToExerciseTotal: 'Taxes due to exercising',
  serviceFee: 'Service fee',
  serviceFeeDescription: `- One-on-one consultations with Secfi’s FINRA-licensed Equity Strategists - personalized assistance planning your taxes, exercise, and/or liquidity approach.\n\r- A detailed proposal outlining all line items to do with your financing, potential future equity valuation, capital gains, fees, and taxes.\n\r- Covers all of the costs of Secfi brokering the deal between you and all parties involved, including coordination with your company’s equity administrator(s), Secfi Capital providers, account opening(s) for your financed equity.\n\r- Just like the rest of the cash advance, you only pay this fee back after a successful exit.\n\r- Our white glove client success team works with you every step of the way from the beginning of your financing transaction until after your company’s exit. \n\r- Our standard service fee is 5% of your financing transaction or a minimum of $5,000.`,
  trustSetupFee: 'Closing fee',
  trustSetupFeeDescription: `If your proposal includes a trust account, a professional trustee will  administer your trust. The trust fees include account opening, account management, and account closure fees.\n\nNot all Secfi proposals include trust accounts. If your proposal does not include a trust fee, you'll see "$0.00" in your proposal.`,
  trustSetupFeeLiquidityDescription: `Pays for the trust account where your shares will be held. An independent trustee administers the trust account to ensure assets are protected for all parties involved in the transaction.`,
  cashLeftOver: 'Cash left over',
  cashLeftOverDescription: `Use this cash as you wish - it's the extra amount financed after exercise expenses are covered.`,
  optionsToExercise: 'Options to exercise',
  avgStrikePrice: 'Avg. strike price',
  incomeTaxes: 'Income taxes',
  amt: 'AMT',
  capitalGains: 'Capital gains',
  secfiAdvance: 'Secfi advance',
  platformRate: 'Fee percentage',
};

export const confirmationWording = {
  title: 'Review your proposal details',
  blurb: `We're excited that you're moving forward with this proposal. Before you continue, please take a minute to review the details below.`,
  overviewTitle: 'Personal information',
  button: 'Confirm',
  mobileButton: 'Proceed',
  backButton: 'Back',
};

export const proposalOverviewWording = {
  company: 'Company',
  shares: 'Shares used for financing',
  advance: 'Advance amount',
  advanceRate: 'Advance rate',
  equityShare: 'Equity share',
  minimumSettlement: 'Minimum settlement timeline',
};

export const notificationsWording = {
  success: 'Proposal accepted',
  error: 'Error accepting terms',
};

export const filesWording = {
  files: 'Files',
};

export const proposalSuccessAnimationWording = {
  genericTitle: 'Thank you',
  title: 'Thank you, {{ firstName }}',
  subTitle: [
    `We're delighted you're choosing Secfi`,
    `<br />`,
    `and taking control of your equity`,
  ].join('\n'),
  summaryTitle: `What's next?`,
  summaryItems: [
    `Our team will start arranging your financing`,
    `You'll be asked to submit some information and documents`,
    `Once all is set, we'll send you the contracts`,
  ],
  button: `Got it`,
};
