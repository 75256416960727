import { createContext, useContext } from 'react';

import { RestApiClient } from './types';

export const RestApiClientContext = createContext<RestApiClient | null>(null);

export const RestApiClientProvider = RestApiClientContext.Provider;

export const useRestApiClient = (): RestApiClient => {
  const client = useContext(RestApiClientContext);
  if (client === null) {
    throw new Error(
      'no RestApiClient found. make sure to wrap the app in <RestApiClientProvider>'
    );
  }
  return client;
};
